<template>
  <div class="wrapper">
    <header class="header-fixed">
      <div class="header-main">
        <div class="header-logo">
          <div class="header-logo_img">
            <img src="@/assets/img/paw-logo.png" alt="">
          </div>
          <div class="header-logo_title">CAT MONEY</div>
        </div>
      </div>
    </header>

    <section class="tour-suggest list-order">
      <div class="flex justify-between items-center relative">
        <div class="flex items-center back-btn">
          <router-link to="/my">
            <i class="ti ti-chevron-left text-white back-btn-icon"></i>
          </router-link>
        </div>
        <div class="title-primary text-white text-title">
          Thay đổi mật khẩu đăng nhập
        </div>
      </div>
      <div class="change-pass">
        <div class="w-full">
          <p class="change-pass-text-2">
            Mật khẩu cũ<span style="color: red; padding-left: 4px">*</span>
          </p>
          <input type="password" class="change-pass-input-2" v-model="password"/>
        </div>
        <div class="w-full">
          <p class="change-pass-text-2">
            Mật khẩu mới<span style="color: red; padding-left: 4px">*</span>
          </p>
          <input v-model="new_password" type="password" class="change-pass-input-2" />
        </div>
        <div class="w-full">
          <p class="change-pass-text-2">
            Xác nhận mật khẩu<span style="color: red; padding-left: 4px">*</span>
          </p>
          <input v-model="confirm_password" type="password" class="change-pass-input-2" />
        </div>
        <button class="save-btn" @click="update">Lưu lại</button>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/views/user/components/Footer'
import network from '@/core/network'
export default {
  components: {
    Footer
  },
  data() {
    return {
      new_password: '',
      password: '',
      confirm_password: ''
    }
  },
  mounted() {
    // this.getUserRank();
  },
  methods: {
    update() {
      if(!this.new_password || !this.password || !this.confirm_password) {
        this.$toast.open({
          message: 'Vui lòng điền đầy đủ thông tin !',
          type: 'error',
          position: 'top',
          duration:3000,
        });
        return false;
      }

      if (this.new_password != this.confirm_password) {
        this.$toast.open({
          message: 'Mật khẩu không trùng khớp !',
          type: 'error',
          position: 'top',
          duration:3000,
        });
        return false;

      }
      network.post('/users/password',{
        new_password: this.new_password,
        password: this.password
      }).then(res => {
          this.$toast.open({
            message: res.data.message,
            type:'success',
            position: 'top',
            duration:3000,
          });
          this.$router.push({
            path: '/my'
          })
        } ).catch(err => {
          this.$toast.open({
            message: err.response.data.message,
            type:'error',
            position: 'top',
            duration:3000,
          });
        })
    }
    // getUserRank() {
    //     network.get('/users/get_user_rank').then( res => {
    //       this.users = res.data.data.docs;
    //     }).catch(err => {
    //       console.log(err);
    //     })
    //   },
    // formatDate(timestamp) {
    //   const date = new Date(timestamp);
    //   return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    // },
    // formatMoney(number) {

    //   return parseInt(number).toLocaleString('vi-VN', {
    //     useGrouping: true,
    //     minimumFractionDigits: 0
    //   }).replace(/\./g, ',');
    // }
  }

}
</script>

<style></style>